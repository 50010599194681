<template>
  <div class="bg-black h-full">
    <!-- Notifications -->
    <Messages />
    <LoadingSpinner />
    <router-view />
  </div>
</template>

<script>
import Messages from "@/components/messages/Wrapper";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: 'App',
  components: {
    Messages,
    LoadingSpinner,
  },
}
</script>

<style>
</style>
