// credit to: https://github.com/intlify/vue-i18n-next/blob/master/examples/lazy-loading/vite/src/i18n.ts
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import en from './locales/en/messages.json';
import nl from './locales/nl/messages.json';

export function setupI18n(options = { locale: 'nl-NL' }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale = 'nl-NL') {
  // console.log('mode', i18n, i18n.mode, locale)
  if (i18n.mode === undefined){
    // passed in this.$i18n
    i18n.locale = locale
  } else if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }

  axios.defaults.headers.common['Accept-Language'] = locale
   
  document.querySelector('html').setAttribute('lang', locale)
}

// export async function loadLocaleMessages(i18n, locale) {
//   // load locale messages
//   if (!i18n.global.availableLocales.includes(locale)) {
//     const messages = await import(`./locales/${locale}.js`)
//     i18n.global.setLocaleMessage(locale, messages.default)
//   }
// }

const i18n = setupI18n({
    legacy: false,
    locale: 'nl-NL',
    fallbackLocale: 'en-GB',
    messages: {
      'nl-NL': nl,
      'en-GB': en
    }
})

export function getGlobalModifiedT(prefix) {
  return (...args) => {
    if (args[0]) {
      args[0] = prefix + args[0];
    }

    return i18n.global.t(...args);
  }
}

export default i18n