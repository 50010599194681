import axios from 'axios';
import store from '@/store';
import { convertToFormData } from "@/helpers/FormHelper";

const get_profile_path = "v1/trainee/auth"; // get
const update_profile_path = "v1/trainee/account"; // patch

function getProfile()
{
    return new Promise((resolve, reject) => {
        axios.get(get_profile_path)
            .then((response)=>{
                // console.log('getTrainee', response)
                store.commit('setUser', response.data.trainee);
                resolve(response.data.trainee);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}


function updateProfile(data)
{
    const url = update_profile_path + '?_method=patch';

    const formData = convertToFormData(data);

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
    };

    return new Promise((resolve, reject) => {
        axios.post(url, formData, config)
            .then((response)=>{
                store.commit('setUser', response.data.trainee);
                resolve(response.data.trainee);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { getProfile, updateProfile };
