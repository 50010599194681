import CookieHelper from '@/helpers/CookieHelper';
import store from '@/store';

function isLoggedIn()
{
    const local_token = store.getters['getToken'];
    if(local_token){
        return Boolean(local_token.access_token);
    }
    return Boolean(CookieHelper.getToken().access_token);
}

function isVerified()
{
    const user = store.getters['getUser'];

    return user.verified;
}

export { isLoggedIn, isVerified };