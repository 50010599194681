import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import "./axios";
import "./downloadUser";
import "./stripe"

import '@/assets/css/tailwind.css'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.mount('#app');