import { isVerified } from "@/helpers/storage/AuthHelper";
import messages from "@/helpers/storage/MessageHelper";

export default (to, from, next) => {
  if (!isVerified()) {
    next({ name: 'email-verification.resend' });
    messages.warn("Je moet eerst je emailadres bevestigen voordat je verder kan.");
    return false;
  }
}
