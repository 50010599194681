import store from "@/store";
import { getProfile } from "@/helpers/api/AccountHelper";

// this file reloads the user object from the api if it's no longer present.
// this function will not fetch unless a user token is present

async function reloadUserModel() {
    const localUser = JSON.parse(localStorage.getItem('trainee'));
    if (localUser) {
        // console.log(localUser)
        store.commit('setUser', localUser);
        return;
    }

    // retrieve user from api 
    const isUserSet = store.getters['isUserSet'];
    const token = store.getters['getToken'];
    if (token && !isUserSet) {
        const user = await getProfile()
        if (process.env.NODE_ENV === 'development') {
            console.log('fetched new User instance from API:', user)
        }
    }
}

reloadUserModel()