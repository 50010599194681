import {loadStripe} from '@stripe/stripe-js';

const stripePublikKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY

let stripe = undefined;

async function init (){
    if (!stripe) {
        stripe = await loadStripe(stripePublikKey);
    }

    return stripe
}

export default init()