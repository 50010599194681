import { createStore } from 'vuex'
import auth from './modules/auth';
import messages from './modules/messages';
import loading from './modules/loading';
import registerForm from './modules/registerForm';
import updateMatch from './modules/updateMatch';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    messages,
    loading,
    registerForm,
    updateMatch,
  }
})
