import store from '@/store';

function setLoading(loading) 
{
    store.commit('loading/setLoading', { loading });
}

function startSpinner()
{
    store.commit('loading/setLoading', { loading: true });
}

function stopSpinner()
{
    store.commit('loading/setLoading', { loading: false });
}

function isLoading()
{
    return store.getters['loading/getLoading'];
}

export { isLoading, setLoading, startSpinner, stopSpinner };