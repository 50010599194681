<template>
    <div v-if="show">
        <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <!-- Background overlay -->
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-scorelitorange opacity-25"></div>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                <div class="inline-block align-bottom transform transition-all sm:my-8 sm:align-middle " role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isLoading } from "@/helpers/storage/loadingSpinnerHelper";

export default {
    name: "LoadingSpinner",
    watch:{
        loading: function(value){
            if (value){
                setTimeout(() => this.delayIsDone = this.loading, 200);
            } else {
                this.delayIsDone = false;
            }
        }
    },
    data(){
        return {
            delayIsDone: false,
        }
    },
    computed: {
        loading: function () {
            return isLoading();
        },
        show: function () {
            return this.loading && this.delayIsDone;
        }
    }
}
</script>


<style scoped>
    /* credit: https://tailwindcomponents.com/component/spinner */
    .loader {
        border-top-color: #F7941D;
        -webkit-animation: spinner .75s linear infinite;
        animation: spinner .75s linear infinite;
    }

    @-webkit-keyframes spinner {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>