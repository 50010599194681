import matchCreate from "./matchCreate";
import store from '@/store'

const Dashboard                 = () => import("@/views/Dashboard");
const EmailVerificationVerify   = () => import("@/views/auth/email/Verify");
const EmailVerificationResend   = () => import("@/views/auth/email/Resend");
const ExpertIndex               = () => import("@/views/expert/Index");
const ExpertShow                = () => import("@/views/expert/Show");
const Profile                   = () => import("@/views/profile/Show");
const ProfileEdit               = () => import("@/views/profile/Edit");
const ProfileEditPassword       = () => import("@/views/profile/EditPassword");
const ReviewIndex               = () => import("@/views/review/Index");
const ReviewShow                = () => import("@/views/review/Show");
const ReviewShowGeneral         = () => import("@/views/review/ShowGeneralPoints");
const ReviewShowHighlights      = () => import("@/views/review/ShowAllHighlights");
const ReviewShowCategory        = () => import("@/views/review/ShowCategory");
const ReviewShowVideo           = () => import("@/views/review/ShowVideo");
const ReviewCreate1             = () => import("@/views/review/create/UploadProfileImage");
const ReviewCreate2             = () => import("@/views/review/create/SelectMatch");
const ReviewCreate3             = () => import("@/views/review/create/SelectProduct");
const ReviewCreate4             = () => import("@/views/review/create/Submit");
const ReviewCreate5             = () => import("@/views/review/create/Success");
const MatchIndex                = () => import("@/views/match/Index");
const MatchShow                 = () => import("@/views/match/Show");
const MatchEdit                 = () => import("@/views/match/Edit");
const Settings                  = () => import("@/views/Settings");
const ImportVideo               = () => import("@/views/import/Video");



// import middleware
import log from "../middleware/log";
import verified from "../middleware/verified";
import notVerified from "../middleware/notVerified";

const authenticated_middleware = [log, verified];

const authRoutes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: authenticated_middleware,
            unbounded: true
        },
    },
    {
        path: '/email/verify/',
        name: 'email-verification.resend',
        component: EmailVerificationResend,
        meta: {
            middleware: [log, notVerified],
        },
    },
    {
        path: '/api/v1/trainee/auth/email/verify/:id/:hash',
        redirect: ({ params, query }) => {return { name: 'email-verification.verify', params, query }}
    },
    {
        path: '/email/verify/:id/:hash',
        name: 'email-verification.verify',
        component: EmailVerificationVerify,
        meta: {
            middleware: [log, notVerified],
        },
    },
    {
        path: '/experts/:role',
        name: 'expert.index',
        component: ExpertIndex,
        meta: {
            middleware: authenticated_middleware,
        },
        props:true,
    },
    {
        path: '/expert/:id',
        name: 'expert.show',
        component: ExpertShow,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/review',
        name: 'reviews',
        component: ReviewIndex,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/review/:id',
        name: 'review.show',
        component: ReviewShow,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/:id/general',
        name: 'review.show.general',
        component: ReviewShowGeneral,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/:id/highlights',
        name: 'review.show.highlights',
        component: ReviewShowHighlights,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },   
    {
        path: '/review/:id/:category',
        name: 'review.show.category',
        component: ReviewShowCategory,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/:id/video',
        name: 'review.show.video',
        component: ReviewShowVideo,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/create',
        name: 'review.create',
        redirect: ({ query }) => {
            const user = store.getters['getUser'];
            if(!user.profile_image){
                return {name: 'review.create.1'};
            }
            if(query.match_id === undefined){
                return {name: 'review.create.2'};
            }
            if(query.product_id === undefined){
                return {name: 'review.create.3'};
            }
            return {name: 'review.create.4'};
        },
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/review/create/step/1',
        name: 'review.create.1',
        component: ReviewCreate1,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/create/step/2',
        name: 'review.create.2',
        component: ReviewCreate2,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/create/step/3',
        name: 'review.create.3',
        component: ReviewCreate3,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/create/step/4',
        name: 'review.create.4',
        component: ReviewCreate4,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/review/create/step/success',
        name: 'review.create.5',
        component: ReviewCreate5,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/match',
        name: 'matches',
        component: MatchIndex,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/match/:id',
        name: 'match.show',
        component: MatchShow,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    ...matchCreate,
    {
        path: '/match/:id/edit',
        name: 'match.edit',
        component: MatchEdit,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/payment/success',
        name: 'payment.status.success',
        component: () => import("@/views/payment/Success"),
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/payment/cancel',
        name: 'payment.status.cancel',
        component: () => import("@/views/payment/Cancel"),
        meta: {
            middleware: authenticated_middleware,
        },
        props: true,
    },
    {
        path: '/profile/edit',
        name: 'profile.edit',
        component: ProfileEdit,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/profile/edit/password',
        name: 'profile.edit.password',
        component: ProfileEditPassword,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/team',
        name: 'teams',
        component: () => import("@/views/team/Index.vue"),
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            middleware: authenticated_middleware,
        },
    },
    {
        path: '/import/video/:uuid',
        name: 'import.video',
        component: ImportVideo,
        meta: {
            middleware: authenticated_middleware,
        },
        props: true
    },
]

export default authRoutes;