
export default {
    namespaced: true,

    state: {
        form: {
            first_name:             "",
            last_name:              "",
            email:                  "",
            password:               "",
            password_confirmation:  "",
            birth_day:              "",
            sport_id:               "",
            terms_and_conditions:   false,
            accept_newsletter:      false,
        },
        errors: {
            first_name:             [],
            last_name:              [],
            email:                  [],
            password:               [],
            password_confirmation:  [],
            birth_day:              [],
            sport_id:               [],
            terms_and_conditions:   [],
            accept_newsletter:      [],
        },
    },
    mutations: {
        setFormValue({ form }, payload) {
            form[payload.key] = payload.value;
        },
        setErrors(store, payload) {
            store.errors = payload.errors;
        },
        setError(store, payload) {
            store.errors[payload.key] = payload.errors ?? [];
        },
    },
    actions: {},
    getters: {
        error: (store) => (key) => {
            if (!store.errors){
                return [];
            }
            return store.errors[key];
        },
        form: ({form}) => {
            return form;
        },
    }
}
