const MatchCreate1   = () => import("@/views/match/create/Create1");
const MatchCreate2   = () => import("@/views/match/create/Create2");
const MatchCreate3   = () => import("@/views/match/create/Create3");
const MatchCreate4   = () => import("@/views/match/create/Create4");


// import middleware
import auth from "../middleware/auth";
import log from "../middleware/log";
import verified from "../middleware/verified";

const authenticated_middleware = [log, auth, verified];

const matchCreateRoutes = [
    {
        path: '/match/create',
        name: 'match.create',
        component: MatchCreate1,
        meta: {
            middleware: authenticated_middleware,
            fullscreen: true,
        },
    },
    {
        path: '/match/create/2',
        name: 'match.create.2',
        component: MatchCreate2,
        meta: {
            middleware: authenticated_middleware,
            fullscreen: true,
        },
    },
    {
        path: '/match/:id/update/1',
        name: 'match.create.3',
        component: MatchCreate3,
        meta: {
            middleware: authenticated_middleware,
            fullscreen: true,
        },
        props: true,
    },
    {
        path: '/match/:id/update/2',
        name: 'match.create.4',
        component: MatchCreate4,
        meta: {
            middleware: authenticated_middleware,
            fullscreen: true,
        },
        props: true,
    },
]

export default matchCreateRoutes;