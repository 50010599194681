const Register                  = () => import("@/views/auth/Register");
const Register2                 = () => import("@/views/auth/Register2");
const Register3                 = () => import("@/views/auth/Register3");
const Register4                 = () => import("@/views/auth/Register4");
const Register5                 = () => import("@/views/auth/Register5");
const Register6                 = () => import("@/views/auth/Register6");

// import middleware
import guest from "../middleware/guest";
import log from "../middleware/log";

const registration_middleware = [log, guest];

const registerRoutes = [
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            middleware: registration_middleware,
        },
    },
    {
        path: '/register/2',
        name: 'register.2',
        component: Register2,
        meta: {
            middleware: registration_middleware,
        },
    },
    {
        path: '/register/3',
        name: 'register.3',
        component: Register3,
        meta: {
            middleware: registration_middleware,
        },
    },
    {
        path: '/register/4',
        name: 'register.4',
        component: Register4,
        meta: {
            middleware: registration_middleware,
        },
    },
    {
        path: '/register/5',
        name: 'register.5',
        component: Register5,
        meta: {
            middleware: registration_middleware,
        },
    },
    {
        path: '/register/6',
        name: 'register.6',
        component: Register6,
        meta: {
            middleware: registration_middleware,
        },
    },
]

export default registerRoutes;