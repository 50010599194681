<template>
    <div class="fix-top flex justify-center transition-all duration-300">
        <div class="w-full">
            <transition name="fade" v-for="message in messages" :key="message.id">
                <Message :message="message" v-on:dismiss="dismissMessage" class="z-40" />
            </transition>
        </div>
    </div>
</template>

<script>
import Message from "@/components/messages/Message";
import messages from "@/helpers/storage/MessageHelper";

export default {
    name: "Messages",
    methods:{
        dismissMessage(id){
            messages.dismiss(id);
        },
    },
    computed:{
        messages: function(){
            return messages.all();
        } 
    },
    components: {
        Message,
    },
}
</script>

<style scoped>
    .fix-top{
        position: fixed;
        top: 0;
        width: 100%;
        /* border: 3px solid #73AD21; */
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>