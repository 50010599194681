import registerRoutes from "./routes/register";
import authRoutes from "./routes/auth";



// import views
const Welcome                   = () => import("@/views/Welcome");
const DashboardWrapper          = () => import("@/wrappers/Dashboard3");
const Login                     = () => import("@/views/auth/Login");
const NotFound                  = () => import("@/views/NotFound");
const ForgotPassword            = () => import("@/views/auth/password/Forgot");
const ResetPassword             = () => import("@/views/auth/password/Reset");

// import middleware
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import log from "./middleware/log";

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
        meta: {
            middleware: [log, guest],
        },
    },
    ...registerRoutes,
    {
        path: '/',
        component: DashboardWrapper,
        meta: {
            middleware: [log, auth],
        },
        children: authRoutes
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/api/v1/trainee/auth/password/reset/:token',
        redirect: ({ params, query }) => {return { name: 'password.reset', params, query }}
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: ResetPassword,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/password/reset',
        name: 'password.forgot',
        component: ForgotPassword,
        meta: {
            middleware: [log, guest],
        }
    },
    {
        path: "/:catchAll(.*)",
        name: '404',
        component: NotFound,
    }
]

export default routes