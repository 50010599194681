import Cookies from 'js-cookie';

const ATTRIBUTES = {
    sameSite: 'strict'
};

function setToken(token, extra_attributes = {})
{
    // Clone the default values in a new object to prevent overriding.
    const default_attributes = Object.assign({}, ATTRIBUTES);

    // Add extra atributes
    const attributes = Object.assign(default_attributes, extra_attributes);

    Cookies.set('access_token', token.access_token, attributes)
    Cookies.set('refresh_token', token.refresh_token, attributes)
    Cookies.set('expires_in', token.expires_in, attributes)
}

function getToken()
{
    return {
        access_token: Cookies.get('access_token'),
        refresh_token: Cookies.get('refresh_token'), 
        expires_in: Cookies.get('expires_in'), 
    };
}

function deleteToken()
{
    Cookies.remove('access_token', ATTRIBUTES);
    Cookies.remove('refresh_token', ATTRIBUTES);
    Cookies.remove('expires_in', ATTRIBUTES);
}

export default { setToken, getToken, deleteToken };