import store from "@/store";
import axios from "axios";
import CookieHelper from '@/helpers/CookieHelper';
import messages from "@/helpers/storage/MessageHelper";
import router from './router'
import { stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";

// import translation function
import { getGlobalModifiedT } from "@/i18n";
const t = getGlobalModifiedT('helpers.AxiosNetworkPrompts.');

// Set the base url of axios.
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Set default headers
axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
axios.defaults.headers.common["Access-Control-Allow-Methods"] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers.common["Access-Control-Allow-Headers"] = 'Origin, Content-Type, X-Auth-Token';

// Load token from cookies if it already exists.
const token = CookieHelper.getToken().access_token;
if (token){
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    store.commit('setToken', CookieHelper.getToken());
}

// Subscribe to vuex token change, and set the default header.
store.subscribe(mutation => {
  if (mutation.type === "setToken"){
    var token = mutation.payload ? `Bearer ${mutation.payload.access_token}` : null;
    axios.defaults.headers.common["Authorization"] = token;
  }
});

// Intercept 401 responses and "empty" responses (server could not be reached)
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isCancel(error)) {
            console.log('request canceled')
        } else if (!error.response) {
            messages.error(t('could_not_reach_server'));
            error.response = { data: {message: 'Server unreachable.'}}
        } else if (error.response.status === 401) {
            // clear cookies/store
            CookieHelper.deleteToken();
            store.commit('setToken', null);
            store.commit('setUser', null);
            
            //redirect
            router.replace({ name: "login" });

            messages.error(t('session_expired'));
        } else if (error.response.status >= 500) {
            // global catch for 5xx errors
            messages.error(t('500'));
            console.error(error)
            stopSpinner()
            return;
        }
        return Promise.reject(error);
    }
);


