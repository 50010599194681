import {createRouter, createWebHistory} from 'vue-router'
import routes from "./router-config";
import VueRouteMiddleware from 'vue-route-middleware';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Hook in middleware
router.beforeEach(VueRouteMiddleware());

export default router
