
export default {
    namespaced: true,

    state: {
        messages: [],
        next_id: 0,
    },
    mutations: {
        setMessage(state, payload){
            state.messages.push({
                id: state.next_id++,
                type: payload.type,
                text: payload.text,
                dismissed: false,
            })
        },
        dismissMessage(state, payload){
            state.messages.find(m => m.id == payload.id).dismissed = true;
        },
        destroyMessage(state, id) {
            state.messages = state.messages.filter(m => m.id !== id);
        }
    },
    actions: {
        setError({ commit }, message) {
            commit('setMessage', {
                type: 'error',
                text: message,
            })
        },
        setWarning({ commit }, message) {
            commit('setMessage', {
                type: 'warning',
                text: message,
            })
        },
        setSuccess({ commit }, message) {
            commit('setMessage', {
                type: 'succes',
                text: message,
            })
        },
        setInfo({ commit }, message) {
            commit('setMessage', {
                type: 'info',
                text: message,
            })
        },
    },
    getters: {
        getMessages: (state) => {
            return state.messages.filter(m => !m.dismissed);
        },
    }
}
