
export default {
    namespaced: true,

    state: {
        matches: {},
    },
    mutations: {
        setMatch({ matches }, payload) {
            matches[payload.id] = payload;
        },
    },
    actions: {},
    getters: {
        matchById: ({matches}) => (id) => {
            return matches[id];
        },
    }
}
