import i18n, { setI18nLanguage } from "../../i18n";

const getInitialUserState = () => {
    return {
        // include property here if you want it to be reactive
        average_rating: 0,
        club: "",
        email: "",
        focus: "",
        position: "",
        profile_image: "",
        sex: "",
        token_balance: 0,
        // verified: 1, // leave this out, it doesn't need to be reactive, and both default values give trouble
    }
}

const isObjectSimilar = (obj, obj2) => {
    return JSON.stringify(obj) == JSON.stringify(obj2);
}

export default {
    state: {
        token: null,
        user: getInitialUserState(),
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('trainee', JSON.stringify(user));

            if(user) {
                setI18nLanguage(i18n, user.locale)
            }
        }
    },
    actions: {},
    getters: {
        getToken: (state) => {
            return state.token;
        },
        getUser: (state) => {
            return state.user ?? JSON.parse(localStorage.getItem('trainee'));
        },
        isUserSet: (state) => {
            return !isObjectSimilar(state.user, getInitialUserState());
        },
    }
}
